<template>
  <el-card shadow="never">
    <el-row style="margin-bottom: 22px">
      <el-input v-model="queryInfo.lendOrderCode" class="input" placeholder="借出单号" clearable></el-input>
      <el-input v-model="queryInfo.materialCode" class="input" placeholder="备件编码" clearable></el-input>
      <el-button type="primary" @click="currentChange(1)" :loading="loading">查询</el-button>
      <el-button type="primary" @click="dialogVisible = true">添加</el-button>
      <el-button type="primary" @click="importBatch = true">批量导入</el-button>
    </el-row>

    <el-table :data="dataList" border style="width: 100%" stripe>
      <el-table-column align="center" prop="lendOrderCode" label="借出单号"></el-table-column>
      <el-table-column align="center" prop="materialCode" label="备件编码"></el-table-column>
      <el-table-column align="center" prop="lineName" label="生产线"></el-table-column>
      <el-table-column align="center" prop="createUserName" label="创建人"></el-table-column>
      <el-table-column align="center" prop="createTime" label="创建时间"></el-table-column>
      <el-table-column align="center" label="操作">
        <template slot-scope="scope">
          <el-button @click="deleteRule(scope.row)" type="danger" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="page-box">
      <el-pagination background layout="total,prev, pager, next" :page-size="pageSize" :total="total" @current-change="currentChange" :current-page.sync="queryInfo.pageNo"></el-pagination>
    </div>
    <el-dialog title="添加线体设置" :visible.sync="dialogVisible" :close-on-click-modal="false" width="700px" :before-close="handleClose">
      <el-form v-model="formData" label-width="200px">
        <el-form-item label="借出单号：">
          <el-input v-model.trim="formData.lendOrderCode" placeholder="请输入借出单号" style="width: 350px" clearable></el-input>
        </el-form-item>
        <el-form-item label="备件编码：">
          <el-input v-model.trim="formData.materialCode" placeholder="请输入备件编码" style="width: 350px" clearable></el-input>
        </el-form-item>
        <el-form-item label="生产线：">
          <el-select v-model="formData.lineCode" placeholder="请选择生产线" style="width: 350px">
            <el-option
                v-for="option in busLineList "
                :key="option.code"
                :label="option.name"
                :value="option.code"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="addRule">确定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="批量导入" :visible.sync="importBatch" :close-on-click-modal="false" width="800px" :before-close="importClose">
      <ImportComponent template-url="template/ruleSortLine.xlsx" upload-url="ruleSortLine/importBatch"/>
      <div slot="footer" class="dialog-footer">
        <el-button @click="importClose">关闭</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import ImportComponent from "@/components/ImportComponent";
export default {
  name: "RuleSortLine",
  components: {ImportComponent},
  data() {
    return {
      queryInfo: {
        materialCode: '',
        lendOrderCode: '',
        pageNo: 1
      },
      formData: {
        materialCode: '',
        lendOrderCode: '',
        lineCode: ''
      },
      dataList: [],
      busLineList: [],
      total: 0,
      pageSize: 0,
      dialogVisible: false,
      loading: false,
      importBatch: false
    }
  },
  created() {
    this.search();
    this.getBusLine()
  },
  methods: {
    async search() {
      this.loading = true;
      const {data: res} = await this.$axios.post('ruleSortLine/queryList', this.queryInfo);
      this.loading = false;
      if (res.code !== 0) {
        return this.$message.error(res.message);
      }
      this.dataList = res.data.records;
      this.total = res.data.total;
      this.pageSize = res.data.size;
    },
    importClose() {
      this.search();
      this.importBatch = false;
    },
    currentChange(pageNo) {
      this.queryInfo.pageNo = pageNo;
      this.search()
    },
    deleteRule(row) {
      this.$confirm('确定删除吗？', '提示', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        type: 'warning',
      }).then(() => {
        this.$axios.post('ruleSortLine/delete', row.id).then(response => {
          if (response.data.code !== 0) {
            return this.$message.error(response.data.message);
          }
          this.$message.success("删除成功");
          this.search();
        })
      })
    },
    addRule() {
      if (!this.formData.lendOrderCode) {
        return this.$message.error('借出单号不能为空');
      }
      if (!this.formData.lineCode) {
        return this.$message.error('生产线不能为空')
      }

      this.loading = true;
      this.$axios.post('ruleSortLine/add', this.formData).then(response => {
        this.loading = false;
        let res = response.data;
        if (res.code !== 0) {
          return this.$message.error(res.message);
        }

        this.$message.success("添加成功");
        this.search();
        this.handleClose()
        this.formData.materialCode = ''
        this.formData.lendOrderCode = ''
        this.formData.lineCode = ''
      }, error => {
        this.loading = false;
        return this.$message.error('添加失败，' + error.message);
      });
    },
    handleClose() {
      this.dialogVisible = false;
    },
    getBusLine() {
      this.$axios.get('busLine/queryList').then(response => {
        this.loading = false;
        let res = response.data;
        if (res.code !== 0) {
          return this.$message.error(res.message);
        }

        this.busLineList = res.data || []
      }, error => {
        this.loading = false;
        return this.$message.error('获取生产线数据源失败，' + error.message);
      });
    }
  }
}
</script>

<style scoped>
.input {
  width: 300px;
  margin-right: 10px;
}
</style>